import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../utils/PageHeader'
import Services from '../utils/home/Services'
import Calltoaction from '../components/calltoaction'

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(wordpress_id: { eq: 2 }) {
        title
        content
        link
        acf {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
      allWordpressWpServices(sort: { fields: date, order: ASC }) {
        edges {
          node {
            title
            content
            acf {
              icon {
                localFile {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="About"
        keywords={['about']}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      />
      <PageHeader
        img={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="container">
          <h1 className="portfolio-heading">{data.wordpressPage.title}</h1>
        </div>
      </PageHeader>
      <section style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row">
            <div className="col s12 m3 l2">
              <div className="card-image profile">
                <Img
                  fluid={
                    data.wordpressPage.acf.image.localFile.childImageSharp.fluid
                  }
                  alt="zaman"
                />
              </div>
            </div>
            <div className="col s12 m9 l10">
              <div class="card-panel">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Services*/}
      <section className="section container services">
        <h2 className="center-align purple-text text-darken-3">Things I do</h2>
        <div className="row">
          <Services services={data.allWordpressWpServices.edges} />
        </div>
      </section>
      {/*Contact*/}
      <Calltoaction />
    </Layout>
  )
}
export default About
